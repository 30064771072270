import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import Li from '../../components/Li'
import { IoIosAdd, IoMdCreate } from 'react-icons/io'
import {thrash} from '../../assets/svg/other'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPermissionGroups, deletePermissionGroup } from '../../actions/permissions'
import Loader from '../../components/Loader'
import { usersWord } from '../../utils/smallHelpers'
import Modal from './modal'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'


export default function Roles () {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [selectedData, setSelectedData] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getPermissionGroups())
  }, [])
  
  const {permissionGroups: roles, loading} = useSelector(state => state.roles.permission_groups)

  const liClickHandler = (path, name) => () => navigate(`/roles/${path}`)
  
  const closeModal = () => setSelectedData(null)

  const openModal = (data) => () => setSelectedData(data || {})

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deletePermissionGroup(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const {name, pmid, users} = data
    return (
      <Li onClick={liClickHandler(pmid, name)}>
        <div className={styles.firstLiBlock}>
          <div>{name}</div>
          <div>{users} {usersWord(users)}</div>
        </div>
        <div className={styles.secondLiBlock} onClick={e => e.stopPropagation()}>
          <label>
            <input type='checkbox'/>
            Разрешен запуск приложения
          </label>
          <div>
            <IoMdCreate size={19} color='#54657c' onClick={openModal(data)}/>
            <div><i className={styles.trash} onClick={() => setConfirmation({id: pmid, name})}><BiTrash/></i></div>
          </div>
        </div>
      </Li>
    )
  }

  return (
    <div className={styles.wrapper}>
       {confirmation && (
                <Confirmation
                    top="30px"
                    left="40px"
                    title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
                    // confirmation={confirmation?.name} 
                    acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
                    rejectConfirmation={rejectConfirmation}
                />
      )}
        <Modal
          open={!!selectedData}
          closeModal={closeModal}
          data={selectedData}
          addMode={!selectedData?.pmid}
        />
        <h1>Роли</h1>
        {loading
          ? <Loader/>
          : roles && <ul className={styles.rolesList}>{roles.map(renderLi)}</ul>
        }
        <Button
          style={{padding: '.5rem 1.5rem'}}
          onClick={openModal()}  
        >
          <IoIosAdd size={30}/>
          Добавить роль
        </Button>
        <footer className={styles.footer}>
            <Button mode='white'>Назад</Button>
            <Button mode='white' onClick={goHome}>Закрыть</Button>
        </footer>
    </div>
  )
}
