import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import Li from '../../components/Li'
import { IoIosAdd, IoIosClose, IoIosTrash, IoMdTrash } from 'react-icons/io'
import {thrash} from '../../assets/svg/other'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import { useState } from 'react'
import Modal from './Modal'
import { Pagination } from '../../components/Pagination'
import { setPage, getPeriodsList, deletePeriod } from '../../actions/periods'
import Table from '../../components/Table'
import { getSchoolYears, getShifts } from '../../actions/common'
import { Select } from '../../components/ModalInputs'
import Confirmation from '../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'


export default function Periods () {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {list, pageNumber, pageCount, loading} = useSelector(state => state.periods)
  const {shifts = []} = useSelector(state => state.common)

  const t = list && [...list]
  const highLow = t?.sort((a,b)=>  b.s_year_id - a.s_year_id )

  const [selectedItem, setSelectedItem] = useState(null)
  const { schoolYears } = useSelector(state => state.common) 

  const [search, setSearch] = useState({year: '15', term: '0'})
  const [periodForDelete, setPeriodForDelete] = useState(null)

  useEffect(() => {
    dispatch(getSchoolYears())
    dispatch(getShifts())
    }, [])

  useEffect(() => () => dispatch(setPage(0)), [])
  
  useEffect(() => {
    dispatch(getPeriodsList())
  }, [pageNumber])

  const handleSerach = (name, value) => {
    setSearch(prev => ({...prev, [name]: value}));
  }

  useEffect(() => {
    dispatch(getPeriodsList(search.year, search.shift));
  }, [search.year, search.shift]);

  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => setSelectedItem(null)
  
  const goHome = () => navigate('/')

  const handleAdd = () => {
    console.log('add')
  }

  const renderRow = (data) => {
    const { name, s_year_id, starttime, stoptime, lid, status, dopstatus, outstatus  } = data
    return (
      <tr>
        <td onClick={openModal(data)}>{name}</td>
        <td onClick={openModal(data)}>{status}</td>
        <td onClick={openModal(data)}>{dopstatus}</td>
        <td onClick={openModal(data)}>{outstatus}</td>
        <td onClick={openModal(data)}>{starttime}</td>
        <td onClick={openModal(data)}>{stoptime}</td>
        <td><i className={styles.trash} onClick={ deleteClickHandler({id: s_year_id, name})}><BiTrash/></i></td>

      </tr>
    )
  }

  const deleteClickHandler = (lid) => (e) => {
    e.stopPropagation()
    setPeriodForDelete(lid)
  }
  
  const acceptConfirm = () => {
    dispatch(deletePeriod(periodForDelete))
    setPeriodForDelete(null)
  }  
  
  const rejectConfirm = () => {
    setPeriodForDelete(null)
  }

  return (
    <div className={styles.wrapper}>
        {periodForDelete &&
          <Confirmation
            title={`Вы уверены, что хотите удалить занятие ${periodForDelete.name}?`}
            acceptConfirmation={acceptConfirm}
            rejectConfirmation={rejectConfirm}
          />
        }
        <Modal    
          closeModal={closeModal}
          data={selectedItem}
          />
        <h1>Учебный распорядок</h1>
        
        <div className={styles.wrapperRow}>
          <div className={styles.selectWrapper}>
            <Select 
              label={'Учебный год'}
              name={'year'}
              onChange={({target: {name, value}}) => handleSerach(name, value)}
              value={search.year || schoolYears?.[0]?.xp_key}
              >
              {[{name: 'Все', xp_key: '0'}, ...schoolYears]?.map(el => <option value={el.xp_key}>{el.name}</option>)}
            </Select>
          </div>
          <div className={styles.selectWrapper}>
            <Select 
              label={'Смена'}
              name={'shift'}
              onChange={({target: {name, value}}) => handleSerach(name, value)}
              value={search.shift || shifts?.[0]?.value}
              >
              {[{name: 'Все', value: '0'}, ...shifts]?.map(el => <option value={el.value}>{el.name}</option>)}
            </Select>
          </div>
        </div>

        {loading
          ? <Loader/>
          : highLow &&
              <Table style={{marginTop: '2rem', backgroundColor: 'white'}}>
                <thead>
                  <tr className={styles.thead}>
                      <th>Название</th>
                      <th>Уч. период</th>
                      <th>Уч. период ДО</th>
                      <th>Уч. период ВД</th>
                      <th>Время начала</th>
                      <th>Время окончания</th>
                      <th/>
                  </tr>
                </thead>
                <tbody>
                    {highLow?.map(renderRow)}
                </tbody>
              </Table>
        }
        <div className={styles.buttons}>
          <Button icon={IoIosAdd} onClick={openModal()}><IoIosAdd size={30}/>Добавить запись</Button>
        </div>
 
        <footer className={styles.footer}>
            <Button mode='white'>Назад</Button>
            <Button mode='white' onClick={goHome}>Закрыть</Button>
        </footer>
    </div>
  )
}


// const thrashClickHandler = (id) => (e) => {
//   e.stopPropagation()
//   dispatch(deletePeriod(id))
// }
// const renderLi = (data) => {
//   const {name, lid} = data
//   return (
//     <Li onClick={openModal(data)}>
//       <div className={styles.firstLiBlock}>
//         <div>{name}</div>
//       </div>
//       <div className={styles.secondLiBlock}>
//         <div onClick={thrashClickHandler(lid)}>{thrash()}</div>
//       </div>
//     </Li>
//   )
// }

// const pageClick = ({selected}) => dispatch(setPage(selected))

// return (
//   <div className={styles.wrapper}>
//       <Modal
//         closeModal={closeModal}
//         data={selectedItem}
//       />
//       <h1>Учебный распорядок</h1>
//       <Pagination
//         onPageChange={pageClick}
//         pageCount={pageCount}
//         pageNumber={pageNumber}
//         loading={loading && !selectedItem}
//       >
//         {list && <ul className={styles.rolesList}>{list.map(renderLi)}</ul>}
//       </Pagination>
//       <Button
//         style={{padding: '.5rem 1.5rem'}}
//         onClick={openModal()}
//       >
//         <IoIosAdd size={30}/>
//         Добавить этап
//       </Button>