import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import Li from '../../components/Li'
import { IoIosAdd } from 'react-icons/io'
import {thrash} from '../../assets/svg/other'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import { useState } from 'react'
import Modal from './Modal'
import { Pagination } from '../../components/Pagination'
import { deleteSchoolYear, setPage, getSchoolYearsList } from '../../actions/schoolYears'
import Table from '../../components/Table'
import { deleteEducationalStatuses, getEducationalStatuses } from '../../actions/directory'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'



export default function EducationalStatuses () {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {educationalStatus} = useSelector(state => state.directory)
  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);


  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteEducationalStatuses(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  useEffect(() => {
    dispatch(getSchoolYearsList());
    dispatch(setPage(0));
    dispatch(getEducationalStatuses())
  }, [])
  

  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => setSelectedItem(null)
  
  const goHome = () => navigate('/')

  const renderLi = (data) => {
    const {name, type, xp_key} = data
    return (
      <tr>
        <td onClick={openModal(data)}>{name}</td>
        <td onClick={openModal(data)}>{type}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({id: xp_key})}><BiTrash/></i></td>
       
      </tr>
    )
  }

  return (
    <div className={styles.wrapper} style={{width: '700px', margin: '0 auto'}}>
        {confirmation && (
                  <Confirmation
                      top="30px"
                      left="40px"
                      title="Закрыть без сохранения?"
                      // confirmation={confirmation?.name} 
                      acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
                      rejectConfirmation={rejectConfirmation}
                  />
        )}
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          height='500px'
        />
        <h1>Учебные статусы</h1>
        <Table style={{marginTop: '2rem', backgroundColor: 'white'}}>
         <thead>
          <tr>
              <th>Значение</th>
              <th>Тип</th>
              <th>Удалить</th>
          </tr>
          </thead>
         <tbody>

          {educationalStatus?.map(renderLi)}

          </tbody>
        </Table>

        <div style={{display:'flex', flexDirection: "row"}}>
          <Button
              style={{padding: '.5rem 1.5rem', margin: '12px'}}
              onClick={openModal()}
            >
              <IoIosAdd size={30}/>
              Добавить 
            </Button>
        </div>
        <footer className={styles.footer}>
            <Button mode='white'>Назад</Button>
            <Button mode='white' onClick={goHome}>Закрыть</Button>
        </footer>
    </div>
  )
}