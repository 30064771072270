import React from 'react'
import styles from './styles.module.css'
import cn from 'classnames'


export default function Checkbox ({label, withPadding, ...props}) {

  const {isChecked, gradeWeight} = props
  
  return (
    <label className={cn([withPadding ? styles.methodOffice : styles.label, styles.checkbox])} style={gradeWeight && {margin: "0 auto" }}>
        <input type='checkbox' checked={isChecked}{...props}/>
        <p>{label}</p>
    </label>
  )
}
