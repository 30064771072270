import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { personnelModalModes } from '../../assets/const'
import userPhoto from '../../assets/images/user_photo2.png'
import cn from 'classnames'
import {Main, More, Education, Qualification, Award, Punish, Experience, Lessons, Events, Roles, Documents, Attestation} from './ModalTabs'
import { StaffRosterContext } from '../../utils/context'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { createPersonnelInfo, updatePersonnelInfo } from '../../actions/personnel'


export default function ModalComponent ({isOpen, closeModal,  modalOpen, person}) {
  
  const dispatch = useDispatch()

  const [mode, setMode] = useState('main')
  const addMode = modalOpen === 'add' ? true : false

   // TODO 400 
   const handleCreateStaffRoster = (values) => {
        dispatch(createPersonnelInfo(values))
        closeModal()
      }
    // TODO 400
    
    const handleUpdateStaffRoster =  (values) => {
        dispatch(updatePersonnelInfo(values))
        closeModal()
    }

  
  const allForms = useFormik({
    // initialValues: addMode ? {xp_key: '', name: '', type: '', hidden: '', timestamp: ''} : data,
    // initialValues: addMode ? {} : modalOpen,
    initialValues: addMode ? {} : person,
    enableReinitialize: true,
    // validateOnChange: true,
    // validationSchema: validationSchema,
    onSubmit: addMode ? handleCreateStaffRoster : handleUpdateStaffRoster
  })
  const renderLi = ({name, value, icon}) => {
      const selected = value === mode
      return (
          <li
              onClick={() => setMode(value)}
              className={cn(styles.modalMenuItem, {
                  [styles.selectedMenuItem]: selected
              })}
          >
              {icon()}
              {name}
          </li>
      )
  }

  const renderTab = () => {
      switch (mode) {
          case 'main': return <Main/>
          case 'more': return <More/>
          case 'education': return <Education/>
          case 'qualification': return <Qualification table/>
          case 'award': return <Award/>
          case 'punish': return <Punish/>
          case 'experience': return <Experience/>
          case 'lesson': return <Lessons/>
          case 'event': return <Events/>
          case 'role': return <Roles/>
          case 'documents': return <Documents/>
          case 'attestation': return <Attestation/>
          default: return
      }
  }
  
  return (
// TODO
    <StaffRosterContext.Provider value={{closeModal, allForms,  addMode}}> 
        <Modal
            open={isOpen}
            classNames={{ modal: styles.modalMain }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            showCloseIcon={false}  
            animationDuration={1}
        >
            <div className={styles.modalContent}>
                <div className={styles.modalMenu}>
                    <img src={userPhoto} alt='o_O'/>
                    <ul>{personnelModalModes.map(renderLi)}</ul>
                </div>
                <div className={styles.modalMain}>{renderTab()}</div>
                <div className={styles.modalFooter}>
                    <Button mode='bordered'>Пароль</Button>
                    <div className={styles.modalFooterRightBlock}>
                    <div>
                        <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
                        <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
                    </div>
                    <Button mode='white' onClick={allForms.handleSubmit}>Сохранить</Button>
                    <Button mode='white' onClick={closeModal}>Закрыть</Button>
                    </div>
                </div>
            </div>
        </Modal>
    </StaffRosterContext.Provider>

  )
}
