import React from 'react'
import styles from './styles.module.css'
import cn from 'classnames'

export default function Select ({label, error, children, ...props}) {
  
  const inputClassObject = cn(
    styles.input,
    {[styles.error]: error}
  )
  
  return (
    <label className={styles.label}>
      <p>{label}</p>
      <select className={inputClassObject} {...props}>
          {children}
      </select>
      {!!error && <p className={styles.errorText}>{error}</p>}
    </label>
  )
}
