import { BACKEND_URL_PREFIX } from "../assets/const";
import { buildDefaultHeaders } from "./helper";
import { 
  SET_GROUPS_DATA, 
  SET_GROUPS_LOADING, 
  SET_GROUP_CURATORS, 
  SET_GROUP_HISTORY, 
  SET_GROUP_INFO, 
  SET_GROUP_TEAM, 
  SET_GROUPS_MODAL_LOADING,
 } from "./types";
import { checkAndUpdateToken } from "./auth";
import { createNotification } from "../components/Notifications/Notifications";




export const getGroups = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_GROUPS_DATA, payload });
    }
  }
  dispatch({ type: SET_GROUPS_LOADING, payload: false });
};

export const getGroupInfo = (groupId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups/${groupId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_GROUP_INFO, payload });
    }
  }
  dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: false });
};

export const getGroupHistory = (groupId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/group_history/${groupId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_GROUP_HISTORY, payload });
    }
  }
  dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: false });
};

export const getGroupTeam = (groupId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups/${groupId}/listeners`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_GROUP_TEAM, payload });
    }
  }
  dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: false });
};

export const getGroupCurators = (groupId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups/${groupId}/tutors`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_GROUP_CURATORS, payload });
    }
  }
  dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: false });
};

export const updateGroups = (groupId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups/${groupId}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getGroups());
    }
  }
  dispatch({ type: SET_GROUPS_LOADING, payload: false });
};

export const createGroups = (groupId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getGroups());
    }
  }
  dispatch({ type: SET_GROUPS_LOADING, payload: false });
};

export const createHistoryGroups = (groupId,data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/group_history`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getGroupHistory(groupId));
    }
  }
  dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: false });
};

export const updateHistoryGroups = (groupId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/group_history/${groupId}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getGroupHistory(groupId));
    }
  }
  dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: false });
};

export const deleteHistoryGroups = (groupsId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/group_history/${groupsId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно удалено');
      await dispatch(getGroupHistory());
    }
  }
};

// export const createGroups = (data) => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     dispatch({ type: SET_COURSES_LOADING, payload: true });
//     const response = await fetch(`${BACKEND_URL_PREFIX}/groups`, {
//       method: "POST",
//       body: JSON.stringify(data),
//       ...buildDefaultHeaders(getState),
//     });
//     if (response.ok) {
//       await dispatch(getCoursesList());
//     } else {
//       createNotification('error', 'Ошибка')
//     }
//   }
//   dispatch({ type: SET_COURSES_LOADING, payload: false });
// };