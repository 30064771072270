import { BACKEND_URL_PREFIX } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";

import { 
  GET_CLASSBOOK_STUDY_PERIOD,
  SET_CLASSBOOK_LOADING, 
  GET_CLASSBOOK_LESSON, 
  GET_CLASSBOOK_CLASS, 
  GET_CLASSBOOK, 
  GET_CLASSBOOK_GRADE_VALUE,
  GET_LESSON_CHART,
  GET_CLASS_TOPIC,
  GET_EVENT_TOOLS,
  GET_PLANNERF_LIST,
  GET_HOMEWORKF_LIST,
  GET_PLANNER_LIST,
  GET_HOMEWORK_LIST,
  } from "./types";


export const getStudyPeriod = (params) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/terms/year/${typeof params == 'number' ? params  : params.scYear }`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
          });
          if (response.ok) {      
            const scPeriod =(await response.json()).terms;
            dispatch({ type: GET_CLASSBOOK_STUDY_PERIOD, payload: scPeriod });
          }
    };
}
export const getLesson = (params) => async (dispatch, getState) => {
  
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/lessons/${params.scYear}/${params.studyPeriod}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
          });
          if (response.ok) {      
            const lessons =(await response.json()).lessons;
            dispatch({ type: GET_CLASSBOOK_LESSON, payload: lessons });
          }
    };
}

export const getScClass  = (params) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/group/${params.studyPeriod}/${params.lesson}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
          });
          if (response.ok) {      
            const scClass =(await response.json()).groups;
            dispatch({ type: GET_CLASSBOOK_CLASS, payload: scClass });
          }
    };
}

export const getClassbook  = (params) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_CLASSBOOK_LOADING, payload: true });
        const response = await fetch(`${BACKEND_URL_PREFIX}/group_journal/${params.studyPeriod}/${params.lesson}/${params.class}?dropouts=${Number(params.dropouts)}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
          });
          if (response.ok) {      
            const data = await response.json();
            dispatch({ type: GET_CLASSBOOK, payload: data });
          } else {
            dispatch({ type: GET_CLASSBOOK, payload: {classbook: [], class_teacher_fio: '' , teacher_fio: ''} });
          }
    };
        dispatch({ type: SET_CLASSBOOK_LOADING, payload: false });
}

export const getGradeValue  = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/grade_value`, {
          method: "GET",
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {      
          const grade_value =(await response.json()).grade_value;
          dispatch({ type: GET_CLASSBOOK_GRADE_VALUE, payload: grade_value });
        }
  };
}

export const setClassbook  = (params, formData) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_CLASSBOOK_LOADING, payload: true });
        const response = await fetch(`${BACKEND_URL_PREFIX}/upsave_journal_grade`, {
          method: "PUT",
                body: JSON.stringify(params),
                ...buildDefaultHeaders(getState),
          });
          if (response.ok) {      
            await dispatch(getClassbook(formData));
          }
    };
        dispatch({ type: SET_CLASSBOOK_LOADING, payload: false });
}

export const getLessonChart  = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/lesson_chart/${params.studyPeriod}/${params.lesson}/${params.class}`, {
          method: "GET",
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {      
          const data = (await response.json()).lesson_chart;
          dispatch({ type: GET_LESSON_CHART, payload: data });
        }
  };
}

export const getClassTopic  = (year, sheid) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/class_topic/${year}/${sheid}`, {
          method: "GET",
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {      
          const data = (await response.json());
          dispatch({ type: GET_CLASS_TOPIC, payload: data });
        }
  };
}

export const getEventTools  = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/eventtools`, {
          method: "GET",
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {      
          const data = (await response.json()).eventtools;
          dispatch({ type: GET_EVENT_TOOLS, payload: data });
        }
  };
}

export const getPlannerF = (week, sheid) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/PlannerF/file_names/${week}/${sheid}`, {
          method: "GET",
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {      
          const data = (await response.json());
          dispatch({ type: GET_PLANNERF_LIST, payload: data });
        } else {
          dispatch({ type: GET_PLANNERF_LIST, payload: [] });
        }
  };
}

export const addPlannerF_Plan = (data, week, sheid) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  const fd = new FormData();
  fd.append("file", data);

  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/PlannerF/file/${week}/${sheid}`, {
      method: "POST",
      body: fd,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getPlannerF(week, sheid));
    }
  }
};

export const deletePlannerF_Plan = (week, sheid, name) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/PlannerF/file/${week}/${sheid}/${name}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getPlannerF(week, sheid));
    }
  }
};

export const getHomeWorkF = (week, sheid) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/homeworkF/file_names/${week}/${sheid}`, {
          method: "GET",
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {      
          const data = (await response.json());
          dispatch({ type: GET_HOMEWORKF_LIST, payload: data });
        } else {
          dispatch({ type: GET_HOMEWORKF_LIST, payload: [] });
        }
  };
}

export const addHomeWorkF_Plan = (data, week, sheid) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  const fd = new FormData();
  fd.append("file", data);

  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/homeworkF/file/${week}/${sheid}`, {
      method: "POST",
      body: fd,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getHomeWorkF(week, sheid));
    }
  }
};

export const deleteHomeWorkF_Plan = (week, sheid, name) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/homeworkF/file/${week}/${sheid}/${name}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getHomeWorkF(week, sheid));
    }
  }
};

export const getPlanner = (week, sheid) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/Planner/file_names/${week}/${sheid}`, {
          method: "GET",
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {      
          const data = (await response.json());
          dispatch({ type: GET_PLANNER_LIST, payload: data });
        } else {
          dispatch({ type: GET_PLANNER_LIST, payload: [] });
        }
  };
}

export const addPlanner_Plan = (data, week, sheid) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  const fd = new FormData();
  fd.append("file", data);

  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/Planner/file/${week}/${sheid}`, {
      method: "POST",
      body: fd,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getPlanner(week, sheid));
    }
  }
};

export const deletePlanner_Plan = (week, sheid, name) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/Planner/file/${week}/${sheid}/${name}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getPlanner(week, sheid));
    }
  }
};

export const getHomeWork = (week, sheid) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/homework/file_names/${week}/${sheid}`, {
          method: "GET",
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {      
          const data = (await response.json());
          dispatch({ type: GET_HOMEWORK_LIST, payload: data });
        } else {
          dispatch({ type: GET_HOMEWORK_LIST, payload: [] });
        }
  };
}

export const addHomeWork_Plan = (data, week, sheid) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  const fd = new FormData();
  fd.append("file", data);

  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/homework/file/${week}/${sheid}`, {
      method: "POST",
      body: fd,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getHomeWork(week, sheid));
    }
  }
};

export const deleteHomeWork_Plan = (week, sheid, name) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/homework/file/${week}/${sheid}/${name}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getHomeWork(week, sheid));
    }
  }
};