import React, {useEffect, useState} from 'react'
import styles from './styles.module.css'
import moment from 'moment'

import Title from '../../components/Title'
import Search from './Search'
import ThemeList from './ThemeList'
import ThemeModal from './ThemeModal'

import {useDispatch, useSelector} from 'react-redux'
import { Pagination } from '../../components/Pagination'
import { getFAQ, setPage } from '../../actions/FAQ'

const dateFormat = 'YYYY-MM-DD'; // Тип даты
const defaultParams = {
    from_date: moment(new Date()).format(dateFormat),
    to_date: moment(new Date()).format(dateFormat),
    hidden: 0,
    only_new: 0,
    graduates: 0,
    dismissed: 0
}; // Параметры по умолчанию

const Index = () => {

    const dispatch = useDispatch();

    const [params,
        setParams] = useState(defaultParams); // Дата для поиска
    const [type,
        setType] = useState(null); // Тип объявления

    const {list, loading, pageNumber,pageCount} = useSelector(state => state.FAQ); // Список объявлений

    const closeModal = () => { // Закрытие модального окна
        setType(null);
    }

    const modalOpen = (item) => { // Открытие модального окна
        setType(item);
    }

    // useEffect(() => () => dispatch(setPage(0)), []);

    const pageClick = ({selected}) => dispatch(setPage(selected))

    useEffect(() => {
        dispatch(getFAQ({...params, pageNumber}))
      }, [pageNumber])

    return (
        <div className={styles.wrapper}>
            <Title title="Вопрос-ответ"/>
            <Search params={params} setParams={setParams} dateFormat={dateFormat} setType={setType}/>
            <Pagination
                pageCount={pageCount}
                pageNumber={pageNumber}
                onPageChange={pageClick}
                loading={loading}>
    
                <ThemeList params={params} data={list} loading={loading} setType={setType} dateFormat={dateFormat}/> 
            </Pagination>

            {type && <ThemeModal closeModal={closeModal} modalOpen={modalOpen} type={type} setType={setType} params={params}/>}
        
        </div>
    )
}

export default Index