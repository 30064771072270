import React, { useState } from 'react'
import {Main, BasicEducation, AdditionalEducation, ExtracurricularActivities, Electives} from './ModalTabs'
import cn from 'classnames'
import styles from './styles.module.css'
import Modal from '../../components/Modal'
import { educationalStructureModalModes } from '../../assets/const'
import { EducationalStructureContext } from '../../utils/context'


export default function ModalComponent ({data, modalOpen, closeModal, height}) {
  
  const [mode, setMode] = useState('main')

  const renderLi = ({name, value, icon}) => {
    const selected = value === mode
    return (
        <li
            onClick={() => setMode(value)}
            className={cn(styles.modalMenuItem, {
                [styles.selectedMenuItem]: selected
            })}
        >
            {icon()}
            {name}
        </li>
    )
}
 
  const renderTab = () => {
      switch (mode) {
        case 'main': return <Main/>
        case 'basicEducation': return <BasicEducation/>
        case 'additionalEducation': return <AdditionalEducation/>
        case 'extracurricularActivities': return <ExtracurricularActivities/>
        case 'electives': return <Electives/>
          // case 'main': return <Main closeModal={closeModal} data={data} addMode={!data?.typeid} />
        default: return
      }
  }
  
  return (
    <EducationalStructureContext.Provider value={{modalOpen, closeModal}}>
        <Modal
            open={!!modalOpen}
            classNames={{ modal: styles.modalMain }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            showCloseIcon={false}  
            animationDuration={1}
        >
            <div className={styles.modalContent}>
                <div className={styles.modalMenu}>
                    <ul>{educationalStructureModalModes.map(renderLi)}</ul>
                </div>
                {renderTab()}
            </div>
        </Modal>
    </EducationalStructureContext.Provider>
      // <Modal
      //   open={!!data}
      //   menuItem={mode}
      //   setMenuItem={setMode}
      //   height={height}

      // >
      //   {renderTab()}
      // </Modal>
  )
}

