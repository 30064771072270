import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getCandidatesRate } from '../../../../actions/settings/candidates'

const Enrolment = ({ form }) => {
   
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(getCandidatesRate(form.values.year))
  }, [form.values.year])
  
  
  
   
  
  return (
    <div></div>
  )
}

export default Enrolment