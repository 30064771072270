import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { getPlanner, addPlanner_Plan, getHomeWork, addHomeWork_Plan, deleteHomeWork_Plan, deletePlanner_Plan, getEventTools } from '../../../../../actions/classbook';
import { createNotification } from '../../../../Notifications/Notifications';
import Button from '../../../../Button';
import { BiTrash } from 'react-icons/bi';

const TabFilesPlan = ({formData, modalData}) => {
  const dispatch = useDispatch();
  const {planner_list, home_list} = useSelector(state => state.classbook);
  const [file, setFile] = useState(null);
  const [fileHome, setFileHome] = useState(null);
  const { lesson_chart } = useSelector(state => state.classbook);

  useEffect(() => {
    (modalData?.week_id, modalData?.sheid) && dispatch(getPlanner(modalData?.week_id, modalData?.sheid));
    (modalData?.week_id, modalData?.sheid) && dispatch(getHomeWork(modalData?.week_id, modalData?.sheid));
  }, [modalData?.week_id, modalData?.sheid])

  const handleAddFile = (type) => {
    if (type === 'plan') {
      if (file && modalData?.week_id && modalData?.sheid ) {
        dispatch(addPlanner_Plan(file[0], modalData?.week_id, modalData?.sheid));
        
      } else {
        createNotification('error', 'Выберите файл');
      }
    } else if (type === 'fact') {
      if (fileHome && modalData?.week_id && modalData?.sheid ) {
        dispatch(addHomeWork_Plan(fileHome[0], modalData?.week_id, modalData?.sheid));
        
      } else {
        createNotification('error', 'Выберите файл');
      }
    }
    setFile('');
    setFileHome('');
  }

  return (
    <div>

      <div className={styles.buttons_wrap}>
        <div className={styles.buttons}>
          {!file ?
          <input className={styles.input} type="file" onChange={e => setFile(Object.values(e.target.files))} title="Добавить файл"/>
          :
          <><div className={styles.fileName} title={file?.[0]?.name}>{file?.[0]?.name}</div>
          <Button onClick={() => handleAddFile('plan')}>Добавить файл</Button></>}  
        </div>
        <div className={styles.buttons}>
          {!fileHome ?
          <input className={styles.input} type="file" onChange={e => setFileHome(Object.values(e.target.files))} title="Добавить файл"/>
          :
          <><div className={styles.fileName} title={fileHome?.[0]?.name}>{fileHome?.[0]?.name}</div>
          <Button onClick={() => handleAddFile('fact')}>Добавить файл</Button></>}
        </div>
      </div>
      
      <div className={styles.wrapperFiles}>
        <div className={styles.files}>        
          <div className={styles.fileHead}>Планирование</div>
          {planner_list?.map(el => <div className={styles.file}>{el.file_name}
            <i className={styles.remove} onClick={() => dispatch(deletePlanner_Plan(modalData?.week_id, modalData?.sheid, el.file_name))}><BiTrash/></i>
          </div>)}
        </div>
        <div className={styles.files}>
          <div className={styles.fileHead}>Домашнее задание</div>
          {home_list?.map(el => <div className={styles.file}>{el.file_name}
            <i className={styles.remove} onClick={() => dispatch(deleteHomeWork_Plan(modalData?.week_id, modalData?.sheid, el.file_name))}><BiTrash/></i>
          </div>)}
        </div>
      </div>
    
    </div>
  )
}

export default TabFilesPlan