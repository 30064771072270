export const AUTH_COMPLETED = 'AUTH_COMPLETED'
export const SIGNED_OUT = 'SIGNED_OUT'
export const SET_AUTH_USER_INFO = 'SET_AUTH_USER_INFO'
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING'

export const GET_INFO_MAIN = 'GET_INFO_MAIN'

export const GET_LESSON_CHART = 'GET_LESSON_CHART'
export const GET_CLASS_TOPIC = 'GET_CLASS_TOPIC'
export const GET_EVENT_TOOLS = 'GET_EVENT_TOOLS'

export const SET_ROOMS = 'SET_ROOMS'
export const SET_ROOMS_LOAD = 'SET_ROOMS_LOAD'

export const SET_DIRECTORY_OBJECTS_UMB = 'SET_DIRECTORY_OBJECTS_UMB'
export const SET_DIRECTORY_MODAL_LIST = 'SET_DIRECTORY_MODAL_LIST'
export const SET_DIRECTORY_OBJECTS_UMB_LOAD = 'SET_DIRECTORY_OBJECTS_UMB_LOAD'

export const SET_TFO_LIST = 'SET_TFO_LIST'
export const SET_TFO_TYPES_LIST = 'SET_TFO_TYPES_LIST'

export const GET_PLANNERF_LIST = 'GET_PLANNERF_LIST'
export const GET_HOMEWORKF_LIST = 'GET_HOMEWORKF_LIST'

export const GET_PLANNER_LIST = 'GET_PLANNER_LIST'
export const GET_HOMEWORK_LIST = 'GET_HOMEWORK_LIST'

export const SET_PERMISSION_LOADING = 'SET_PERMISSION_LOADING'
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS'
export const SET_PERMISSION_GROUPS = 'SET_PERMISSION_GROUPS'
export const SET_PERMISSION_GROUP_USERS = 'SET_PERMISSION_GROUP_USERS'

export const SET_TEACHERS_BY_GROUP_ID = 'SET_TEACHERS_BY_GROUP_ID'
export const SET_TEACHERS_BY_GROUP_ID_LOADING = 'SET_TEACHERS_BY_GROUP_ID_LOADING'

export const SET_CHAPTERS_LIST = 'SET_CHAPTERS_LIST'
export const SET_ACCESS_ENTITY = 'SET_CHAPTER_ENTITY'
export const SET_ACCESS_GROUP_DATA = 'SET_CHAPTER_GROUP_DATA'
export const SET_ACCESS_LOADING = 'SET_CHAPTERS_LOADING'

export const SET_GROUPS_DATA = 'SET_GROUPS_DATA'
export const SET_GROUP_INFO = 'SET_GROUP_INFO'
export const SET_GROUP_HISTORY = 'SET_GROUP_HISTORY'
export const SET_GROUP_TEAM = 'SET_GROUP_TEAM'
export const SET_GROUP_CURATORS = 'SET_GROUP_CURATORS'
export const SET_GROUPS_MODAL_LOADING = 'SET_GROUPS_MODAL_LOADING'
export const SET_GROUPS_LOADING = 'SET_GROUPS_LOADING'

export const SET_SCHOOL_YEARS_LIST = 'SET_SCHOOL_YEARS_LIST'
export const SET_SCHOOL_YEARS_COUNT = 'SET_SCHOOL_YEARS_COUNT'
export const SET_SCHOOL_YEARS_PAGE = 'SET_SCHOOL_YEARS_PAGE'
export const SET_CURRENT_SCHOOL_YEAR = 'SET_CURRENT_SCHOOL_YEAR'
export const SET_SCHOOL_YEARS_LOADING = 'SET_SCHOOL_YEARS_LOADING'

export const SET_SCHOOL_WEEKS_LIST = 'SET_SCHOOL_WEEKS_LIST'
export const SET_SCHOOL_WEEKS_COUNT = 'SET_SCHOOL_WEEKS_COUNT'
export const SET_SCHOOL_WEEKS_PAGE = 'SET_SCHOOL_WEEKS_PAGE'
export const SET_CURRENT_SCHOOL_WEEK = 'SET_CURRENT_SCHOOL_WEEK'
export const SET_SCHOOL_WEEKS_LOADING = 'SET_SCHOOL_WEEKS_LOADING'

export const SET_SCHEDULE_VARIANTS_LIST = 'SET_SCHEDULE_VARIANTS_LIST'
export const SET_SCHEDULE_VARIANTS_COUNT = 'SET_SCHEDULE_VARIANTS_COUNT'
export const SET_SCHEDULE_VARIANTS_PAGE = 'SET_SCHEDULE_VARIANTS_PAGE'
export const SET_CURRENT_SCHEDULE_VARIANT = 'SET_CURRENT_SCHEDULE_VARIANT'
export const SET_SCHEDULE_VARIANTS_LOADING = 'SET_SCHEDULE_VARIANTS_LOADING'


export const GET_SCHEDULE_CURRENT = 'GET_SCHEDULE_CURRENT'
export const GET_SCHEDULE_WEEKS_LIST = 'GET_SCHEDULE_WEEKS_LIST'
export const SET_SCHEDULE_CURRENT_LOADING = 'SET_SCHEDULE_CURRENT_LOADING'
export const GET_SCHEDULE_CLASS_LIST = 'GET_SCHEDULE_CLASS_LIST'
export const GET_SCHEDULE_CURRENT_NEW = 'GET_SCHEDULE_CURRENT_NEW'
export const SET_SCHEDULE_CURRENT_NEW_LOADING = 'SET_SCHEDULE_CURRENT_NEW_LOADING'

export const SET_TERMS_LIST = 'SET_TERMS_LIST'
export const SET_TERMS_COUNT = 'SET_TERMS_COUNT'
export const SET_TERMS_PAGE = 'SET_TERMS_PAGE'
export const SET_CURRENT_TERM = 'SET_CURRENT_TERM'
export const SET_TERMS_LOADING = 'SET_TERMS_LOADING'

export const SET_PERIODS_LIST = 'SET_PERIODS_LIST'
export const SET_PERIODS_COUNT = 'SET_PERIODS_COUNT'
export const SET_PERIODS_PAGE = 'SET_PERIODS_PAGE'
export const SET_CURRENT_PERIOD = 'SET_CURRENT_PERIOD'
export const SET_PERIODS_LOADING = 'SET_PERIODS_LOADING'

export const SET_COURSES_LIST = 'SET_COURSES_LIST'
export const SET_COURSES_COUNT = 'SET_COURSES_COUNT'
export const SET_COURSES_PAGE = 'SET_COURSES_PAGE'
export const SET_CURRENT_COURSE = 'SET_CURRENT_COURSE'
export const SET_COURSES_LOADING = 'SET_COURSES_LOADING'

export const SET_COMMON_LOADING = 'SET_COMMON_LOADING'
export const SET_COMMON_TEACHERS_LIST = 'SET_TEACHERS_LIST'
export const SET_COMMON_SCHOOL_YEARS = 'SET_SCHOOL_YEARS'
export const SET_COMMON_SPECIALIZATIONS = 'SET_SPECIALIZATIONS'
export const SET_COMMON_LANGUAGES = 'SET_LANGUAGES'
export const SET_COMMON_STUDY_PROFILES = 'SET_STUDY_PROFILES'
export const SET_COMMON_STUDY_MODES = 'SET_STUDY_MODES'
export const SET_COMMON_STUDY_FORMS = 'SET_STUDY_FORMS'
export const SET_COMMON_STUDY_STEPS = 'SET_STUDY_STEPS'
export const SET_COMMON_SHIFTS = 'SET_SHIFTS'
export const SET_COMMON_COUNTRIES = 'SET_COMMON_COUNTRIES'
export const SET_COMMON_SUBJECTS = 'SET_COMMON_SUBJECTS'
export const SET_COMMON_SCHOOLS = 'SET_COMMON_SCHOOLS'
export const SET_COMMON_FAMILY_STATUSES = 'SET_COMMON_FAMILY_STATUSES'
export const SET_COMMON_FAMILY_COMPOSITION = 'SET_COMMON_FAMILY_COMPOSITION'
export const SET_COMMON_TERM_TYPES = 'SET_COMMON_TERM_TYPES'
export const SET_COMMON_TERM_GRADE_TYPES = 'SET_COMMON_TERM_GRADE_TYPES'
export const SET_COMMON_ADMINISTRATION = 'SET_COMMON_ADMINISTRATION'
export const SET_COMMON_PARALLELS = 'SET_COMMON_PARALLELS'
export const SET_COMMON_CLASSES = 'SET_COMMON_CLASSES'
export const SET_COMMON_SCHEDULE_VARIANTS = 'SET_COMMON_SCHEDULE_VARIANTS'

export const SET_CANDIDATES_LIST = 'SET_CANDIDATES_LIST'
export const SET_CANDIDATES_DATA = 'SET_CANDIDATES_DATA'
export const SET_CANDIDATES_ADDRESS = 'SET_CANDIDATES_ADDRESS'
export const SET_CANDIDATES_BENEFITS = 'SET_CANDIDATES_BENEFITS'
export const SET_CANDIDATES_ENTRANCE = 'SET_CANDIDATES_ENTRANCE'
export const SET_CANDIDATES_MEDICINE_INFO = 'SET_CANDIDATES_MEDICINE_INFO'
export const SET_CANDIDATES_ADDITIONAL_EDUCATION = 'SET_CANDIDATES_ADDITIONAL_EDUCATION'
export const SET_CANDIDATES_DOCUMENTS = 'SET_CANDIDATES_DOCUMENTS'
export const SET_CANDIDATES_INFO = 'SET_CANDIDATES_INFO'
export const SET_CANDIDATES_PARENTS = 'SET_CANDIDATES_PARENTS'
export const SET_CANDIDATES_FORM = 'SET_CANDIDATES_FORM'
export const SET_CANDIDATES_EXAM_CITY = 'SET_CANDIDATES_EXAM_CITY'
export const SET_CANDIDATES_LANGUAGES = 'SET_CANDIDATES_LANGUAGES'
export const SET_CANDIDATES_LOADING = 'SET_CANDIDATES_LOADING'

export const SET_REPORTS_LIST = 'SET_REPORTS_LIST'
export const SET_REPORTS_PARAMS = 'SET_REPORTS_PARAMS'
export const SET_REPORTS_LOADING = 'SET_REPORTS_LOADING'

export const GET_CLASSBOOK_STUDY_PERIOD = 'GET_CLASSBOOK_STUDY_PERIOD'
export const SET_CLASSBOOK_LOADING = 'SET_CLASSBOOK_LOADING'
export const GET_CLASSBOOK_LESSON = 'GET_CLASSBOOK_LESSON'
export const GET_CLASSBOOK_CLASS = 'GET_CLASSBOOK_CLASS'
export const GET_CLASSBOOK_GRADE_VALUE = 'GET_CLASSBOOK_GRADE_VALUE'
export const GET_CLASSBOOK = 'GET_CLASSBOOK'

export const GET_PERSONNEL_LIST = 'GET_PERSONNEL_LIST'
export const SET_PERSONNEL_LOADING = 'SET_PERSONNEL_LOADING'
export const GET_PERSON = 'GET_PERSON'
export const GET_PERSON_TABLE = 'GET_PERSON_TABLE'

export const GET_METHOD_OFFICE_CYCLE = 'GET_METHOD_OFFICE_CYCLE'
export const GET_METHOD_OFFICE_ROLES = 'GET_METHOD_OFFICE_ROLES'
export const GET_METHOD_OFFICE_ACCESS = 'GET_METHOD_OFFICE_ACCESS'
export const SET_METHOD_OFFICE_LOADING = 'SET_METHOD_OFFICE_LOADING'
export const GET_METHOD_OFFICE_ACCESS_FILTERED_PERSONNEL = 'GET_METHOD_OFFICE_ACCESS_FILTERED_PERSONNEL'
export const GET_METHOD_OFFICE_ACCESS_PERSONNEL = 'GET_METHOD_OFFICE_ACCESS_PERSONNEL'
export const METHOD_OFFICE_TABLE_CLEAN = 'METHOD_OFFICE_TABLE_CLEAN'
export const GET_METHOD_OFFICE_FILES = 'GET_METHOD_OFFICE_FILES'
export const GET_METHOD_OFFICE_FOLDER = 'GET_METHOD_OFFICE_FOLDER'
export const GET_METHOD_OFFICE_SUBFOLDER = 'GET_METHOD_OFFICE_SUBFOLDER'

export const SET_SETTINGS_MAIN_DATA = 'SET_SETTINGS_MAIN_DATA'
export const SET_SETTINGS_ABOUT_DATA = 'SET_SETTINGS_ABOUT_DATA'
export const SET_SETTINGS_CANDIDATES_RATE = 'SET_SETTINGS_CANDIDATES_RATE'
export const SET_SETTINGS_CANDIDATES_PARAMS = 'SET_SETTINGS_CANDIDATES_PARAMS'
export const SET_SETTINGS_CANDIDATES_NOTICES = 'SET_SETTINGS_CANDIDATES_NOTICES'
export const SET_SETTINGS_CANDIDATES_NOTICES_STAT = 'SET_SETTINGS_CANDIDATES_NOTICES_STAT'
export const SET_SETTINGS_CANDIDATES_EXAM_TICKETS = 'SET_SETTINGS_CANDIDATES_EXAM_TICKETS'
export const SET_SETTINGS_RESULTS_DATA = 'SET_SETTINGS_RESULTS_DATA'
export const SET_SETTINGS_ADMINISTRATION_DATA = 'SET_SETTINGS_ADMINISTRATION_DATA'
export const SET_SETTINGS_EXPORT_DATA = 'SET_SETTINGS_EXPORT_DATA'
export const SET_SETTINGS_KIOSK_DATA = 'SET_SETTINGS_KIOSK_DATA'
export const SET_SETTINGS_KIOSK_VIDEO_DATA = 'SET_SETTINGS_KIOSK_VIDEO_DATA'
export const SET_SETTINGS_STRENGTH_DATA = 'SET_SETTINGS_STRENGTH_DATA'
export const SET_SETTINGS_STRENGTH_TABLE_DATA = 'SET_SETTINGS_STRENGTH_TABLE_DATA'
export const SET_SETTINGS_CONTROL_DATA = 'SET_SETTINGS_CONTROL_DATA'
export const SET_SETTINGS_LOADING = 'SET_SETTINGS_LOADING'

export const SET_STUDENTS_LOADING = 'SET_STUDENTS_LOADING'
export const SET_STUDENTS_LIST = 'SET_STUDENTS_LIST'
export const SET_STUDENTS_DATA_MAIN = 'SET_STUDENTS_DATA_MAIN'
export const SET_STUDENTS_DATA_OTHER = 'SET_STUDENTS_DATA_OTHER'


export const SET_DISCIPLINE_STRUCTURE_FILTER = 'SET_DISCIPLINE_STRUCTURE_FILTER'
export const SET_DISCIPLINE_STRUCTURE_DATA = 'SET_DISCIPLINE_STRUCTURE_DATA'
export const SET_DISCIPLINE_STRUCTURE_LOADING = 'SET_DISCIPLINE_STRUCTURE_LOADING'

export const SET_ROOM_TYPES = 'SET_ROOM_TYPES'
export const SET_ROOM_TYPES_LOAD = 'SET_ROOM_TYPES_LOAD'
export const GET_STUDENT_LIST = 'GET_STUDENT_LIST'
export const GET_STUDENT_LIST_LOADING = 'GET_STUDENT_LIST_LOADING'

export const SET_COURSES_ALIAS_LIST = 'SET_COURSES_ALIAS_LIST'
export const SET_COURSES_ALIAS_LIST_LOADING = 'SET_COURSES_ALIAS_LIST_LOADING' 

export const SET_EDU_DIR_LIST_LOADING = 'SET_EDU_DIR_LIST_LOADING'
export const SET_EDU_DIR_LIST = 'SET_EDU_DIR_LIST'
export const SET_CULTURE_LIST_LOADING = 'SET_CULTURE_LIST_LOADING'
export const SET_CULTURE_LIST = 'SET_CULTURE_LIST'
export const SET_COMPETENCE_LIST = 'SET_COMPETENCE_LIST'
export const SET_COMPETENCE_LIST_LOADING = 'SET_COMPETENCE_LIST_LOADING'

export const SET_EQUIPMENTS_LIST = 'SET_EQUIPMENTS_LIST'
export const SET_EQUIPMENTS_BY_ROOM_LIST = 'SET_EQUIPMENTS_BY_ROOM_LIST'

export const GET_THEME_PLANNING = 'GET_THEME_PLANNING'
export const SET_THEME_PLANNING_LOAD = 'SET_THEME_PLANNING_LOAD'

export const SET_ADS = 'SET_ADS'
export const SET_ADS_LOADING = 'SET_ADS_LOADING'
export const SET_ADS_BY_ID = 'SET_ADS_BY_ID'
export const SET_ADS_BY_ID_LOADING = 'SET_ADS_BY_ID_LOADING'

export const SET_EVENT_TYPES = 'SET_EVENT_TYPES'
export const SET_EVENT_TYPES_LOADING = 'SET_EVENT_TYPES_LOADING'
export const SET_EVENT_TYPE_BY_ID = 'SET_EVENT_TYPE_BY_ID'

export const SET_FAQ = 'SET_FAQ'
export const SET_FAQ_LOADING = 'SET_FAQ_LOADING'
export const SET_FAQ_BY_ID = 'SET_FAQ_BY_ID'
export const SET_FAQ_BY_ID_LOADING = 'SET_FAQ_BY_ID_LOADING'
export const SET_FIO = 'SET_FIO'

export const SET_MESSAGE = 'SET_MESSAGE'
export const SET_MESSAGE_LOADING = 'SET_MESSAGE_LOADING'

export const SET_FORMS_OF_EDUCATION = 'SET_FORMS_OF_EDUCATION'

export const SET_STUDENTS = 'SET_STUDENTS'
export const SET_PARENTS = 'SET_PARENTS'
export const SET_EMPLOYEES = 'SET_EMPLOYEES'
export const SET_ROLES = 'SET_ROLES'
export const SET_FAQ_FILES = 'SET_FAQ_FILES'
export const SET_FAQ_PAGE = 'SET_FAQ_PAGE'
export const SET_FAQ_COUNT = 'SET_FAQ_COUNT'

/// СПРАВОЧНИКИ
export const GET_GRADE_WEIGHT = 'GET_GRADE_WEIGHT'
export const GET_GRADE_OPTIONS = 'GET_GRADE_OPTIONS'
export const GET_TYPES_STUDY_SESSIONS = 'GET_TYPES_STUDY_SESSIONS'
export const GET_EDUCATIONAL_STATUSES = 'GET_EDUCATIONAL_STATUSES'
export const GET_COUNTRIES = 'GET_COUNTRIES'

export const GET_EDUCATIONAL_STRUCTURE = 'GET_EDUCATIONAL_STRUCTURE'
export const GET_EDUCATIONAL_STRUCTURE_DEPARTMENTS = 'GET_EDUCATIONAL_STRUCTURE_DEPARTMENTS'
export const GET_EDUCATIONAL_STRUCTURE_DEPARTMENTS_INFO = 'GET_EDUCATIONAL_STRUCTURE_DEPARTMENTS_INFO'
export const GET_COURSES_FOR_EDUCATIONAL_STRUCTURE = 'GET_COURSES_FOR_EDUCATIONAL_STRUCTURE'
export const GET_CLASSES_FOR_EDUCATIONAL_STRUCTURE = 'GET_COURSES_FOR_EDUCATIONAL_STRUCTURE'


export const GET_COMPETENCE = 'GET_COMPETENCE'

export const SET_SCHEDULING_LOADING = 'SET_SCHEDULING_LOADING'
export const SET_SCHEDULING_DATA = 'SET_SCHEDULING_DATA'

export const GET_CULTURE = 'GET_CULTURE'



