import React, { useEffect, useRef } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, CreatableSelect, Input, MultiSelect, Select } from '../../../components/ModalInputs'
import { createSchoolYear, updateSchoolYear } from '../../../actions/schoolYears'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { getTermGradeTypes, getTermTypes } from '../../../actions/common'
import { createGradeOptions, updateGradeOptions } from '../../../actions/directory'
import Creatable  from 'react-select/creatable';

// const validationSchema = Yup.object({
//   name: Yup.string().required('обязательное поле'),
//   begdate: Yup.string().required('обязательное поле'),
//   enddate: Yup.string().required('обязательное поле'),
// })


export default function Main ({closeModal, data, addMode}) {
  
  const dispatch = useDispatch()  
  const {gradesOptions } = useSelector(state => state.directory)


  const handleCreateGradeOptions = async (values) => {
    const forRequest =
    {...values,
      isgrade: values.isgrade ? -1 : 0,
      isdisease: values.isdisease ? -1 : 0,
    }
    dispatch(createGradeOptions(forRequest))
    closeModal()
  }

  const handleUpdateGradeOptions = async (values) => {
    const forRequest =
    {...values,
      isgrade: values.isgrade ? -1 : 0,
      isdisease: values.isdisease ? -1 : 0,
    }
    dispatch(updateGradeOptions(forRequest))
    closeModal()
  }




  const {
    values, 
    errors,
    touched,
    submitCount,
    handleChange,
    setFieldValue,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? {value: '', text: '', isgrade: '', isdisease: ''} : data,
    enableReinitialize: true,
    // validateOnChange: true,
    // validationSchema: validationSchema,
    onSubmit: addMode ? handleCreateGradeOptions : handleUpdateGradeOptions
  })

  const createOption = (label, dataId) => ({
    label,
    value: dataId,
  });

  const options = gradesOptions.map(({value, valid}) => {
    return createOption(value, valid);
  });
  ;


  const handleChange2 =(value) => {
    setFieldValue('value', value.label)
  }
  return (
    <>
      <div className={styles.modalMain} >
        <div className={styles.tabWrapper} style={{display: "flex", justifyContent: 'space-evenly'}}>
        <div className={styles.inputsColumn}>
       
       <CreatableSelect isClearable options={options}  onChange={handleChange2} label='Значение' placeholder=" " />

    
            {/* <Select
              label='Значение'
              value={values.value}
              name='value'
              // value={getTermGradeValues()}
              // options={data?.map(getMultiOptions)}
              onChange={handleChange}
              isSearchable 
            >
              {gradesOptions?.map(({value, valid}) => <option key={valid} value= {value}>{value}</option>)}
            </Select>    */}
          </div>
          <div className={styles.inputsRow}>
            <Input 
              label='Примечание'
              name='text'
              value={values.text}
              // error={error('name')}
              onChange={handleChange}
              style={{width: '300px'}}
              />
          </div>
         
          <div className={styles.inputsRow}>
              <Checkbox label='Это оценка' isChecked={!!values.isgrade} value={!!values.isgrade} onChange={handleChange} name='isgrade' />
              <Checkbox label='Отсутстве по болезни' isChecked={!!values.isdisease} value={!!values.isdisease} onChange={handleChange} name='isdisease'/>

          </div>
        </div>
      </div>
      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}
