import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { checked } from '../../assets/svg/other'
import {add, remove, change, print, copy} from '../../assets/svg/footers'
import Select from '../../components/Select'
import { useState } from 'react'
import Modal from './modal'
import { useNavigate } from 'react-router-dom'
import Table from '../../components/Table'
import { getPerson, getPersonnelList } from '../../actions/personnel'
import { useDispatch, useSelector } from 'react-redux'


const selectValues = [
    {work_status: 'Работает', id: 1},
    {work_status: 'Не работает', id: 2}
]

export default function Personnel () {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {personnelList, person} = useSelector((state)=> state.personnel)  
  // TODO НУЖЕН ЕЩЕ ОДИН ЗАПРОС НА ПОЛУЧЕНИЕ ПО КОНКРЕТНОМУ ЧЕЛОВЕКУ С КУЧЕЙ ПОЛЕЙ
  // console.log('personnelList::: ', personnelList);

  const [modalOpen, setModalOpen] = useState(false)
  const [work, setWork] = useState( 'Работает')
  // const [addMode, setAddMode] = useState(false)
  
  const openModal = (row) => setModalOpen(row)
  
  const goHome = () => navigate('/')
  
  const closeModal = () => {
    setModalOpen(false)

  }

  useEffect(()=>{
    dispatch(getPersonnelList(work))
    if(modalOpen.mid) {
      dispatch(getPerson(modalOpen.mid))
    }
  }, [work, modalOpen])

  const handleInputChange = (e) => {
    setWork( e.target.value)
  }

  const renderOption = (option, index) => {
    const {  xp_key,  value, trmid, cid, gid, work_status, id, pmid, did ,  name, title, year, access, } = option
    const optionValue =   xp_key || value ||trmid ||cid || gid || work_status || id || pmid || did
    const optionTitle =  name || title || value || year || work_status || access
    return <option key={`${optionValue}-${index}`} value={optionValue}>{optionTitle}</option>
  }

  

  const renderRow = (row) => {
    const {fio, login, sex, age, birthday, email, residential_address, date_of_birth} = row
    return (
      <tr onClick={()=> openModal(row)}>
        <td>{fio}</td>
        <td>{checked()}</td>
        <td>{login}</td>
        <td></td>
        <td>{sex}</td>
        <td>{birthday}</td>
        <td>{age}</td>
        <td>{date_of_birth}</td>
        <td>{email}</td>
        <td>{residential_address}</td>
      </tr>
    )
  }
  
  return (
    <div className={styles.wrapper}>
      {person && 
        <Modal 
          isOpen={!!modalOpen} 
          closeModal={closeModal}
          // addMode={addMode}
          modalOpen={modalOpen}
          person={person} //выбранный препод
        />
      }  
        <h1>Кадровый состав</h1>
        <div className={styles.filter}>
            <Select 
            title='Статус'
            options={selectValues} 
            onChange = {handleInputChange}
            >
              <option value=''>Выбрать</option>
              {selectValues?.map(renderOption)}
            </Select>
            <Button>Обновить</Button>
        </div>
        <Table style={{marginTop: '2rem'}}>
          <thead>
              <tr>
                  <th>Фамилия Имя Отчество</th>
                  <th>Фото</th>
                  <th>Логин</th>
                  <th>Заблокирован</th>
                  <th>Пол</th>
                  <th>Дата рождения</th>
                  <th>Возраст</th>
                  <th>ДР (Месяц/День)</th>
                  <th>Email</th>
                  <th>Адрес</th>
              </tr>
          </thead>
          <tbody>
              {personnelList?.map(renderRow)}
          </tbody>
        </Table>
        <footer className={styles.footer}>
            <div>
                {/* <Button className={styles.footerButton} onClick={openModal} mode='bordered'>{add()}Добавить</Button> */}
                <Button className={styles.footerButton} onClick={()=>openModal('add')} mode='bordered'>{add()}Добавить</Button>
                <Button className={styles.footerButton} mode='bordered'>{remove()}Удалить</Button>
                {/* <Button className={styles.footerButton} onClick={openModal} mode='bordered'>{change()}Изменить</Button>  */}
                <Button className={styles.footerButton} style={{padding: '.5rem 0'}}>{print()}Печать экзаменационных билетов</Button>
                <Button className={styles.footerButton} style={{padding: '.5rem 0'}}>{print()}Печать Т-2</Button>
                <Button className={styles.footerButton} style={{padding: '.5rem 0'}}>{copy()}Пароли / Экспорт в AD</Button>
            </div>
            <Button mode='white' onClick={goHome}>Закрыть</Button>
        </footer>
    </div>
  )
}
