import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import Li from '../../components/Li'
import { IoIosAdd, IoIosClose } from 'react-icons/io'
import {thrash} from '../../assets/svg/other'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import { useState } from 'react'
import Modal from './Modal'
import { Pagination } from '../../components/Pagination'
import { deleteSchoolWeek, getSchoolWeeksList, setPage } from '../../actions/schoolWeeks'
import Table from '../../components/Table'
import Select from '../../components/Select'
import { getSchoolYears } from '../../actions/common'
import { getScheduleWeek } from '../../actions/schedule'
import { getStudyPeriod } from '../../actions/classbook'
import Confirmation from '../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'



export default function SchoolWeeks () {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {list, pageNumber, pageCount, loading} = useSelector(state => state.school_weeks)
  const { schoolYears } = useSelector(state => state.common) 
  const { weeks, schedule, classSchedule } = useSelector(state => state.schedule) 

  const t = list && [...list]
  const highLow = t?.sort((a,b)=>  b.week_id - a.week_id )

  const [selectedItem, setSelectedItem] = useState(null)
  const { studyPeriod } = useSelector(state => state.classbook) 
  const [weekForDelete, setWeekForDelete] = useState(null)
  const [formData, setFormData] = useState({
    scYear: 0,
    studyPeriod:0,
  })

  useEffect(() => {
    dispatch(getSchoolYears())
    }, [])

    useEffect(() => {
      formData.scYear  &&
     dispatch(getStudyPeriod(formData))
    //  setFormData(prev => ({...prev, studyPeriod:'0' }))
    }, [formData.scYear])

  useEffect(() => () => dispatch(setPage(0)), [])
  
  useEffect(() => {
    studyPeriod &&
    formData.scYear && 
    dispatch(getSchoolWeeksList(formData))
  }, [formData])
  

  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => setSelectedItem(null)
  
  const goHome = () => navigate('/')

  const handleInputChange = (e) => {
    const {name, value}  = e.target
        setFormData({
            ...formData,
            [name]: value,
          })
        }

const renderOptions = ({name, title, xp_key, trmid}) => <option key={trmid || xp_key} value={trmid || xp_key}>{name || title}</option>

const deleteClickHandler = (wid) => (e) => {
  e.stopPropagation()
  setWeekForDelete(wid)
}

const acceptConfirm = () => {
  dispatch(deleteSchoolWeek(weekForDelete))
  setWeekForDelete(null)
}  

const rejectConfirm = () => {
  setWeekForDelete(null)
}

const renderLi = (data) => {
    const {week_id, week_name, wstart_date, wend_date, week_comment} = data
    return (
      <tr onClick={openModal(data)}>
        <td>{week_name}</td>
        <td>{wstart_date}</td>
        <td>{wend_date}</td>
        <td>{week_comment}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
        <td><i className={styles.trash} onClick={ deleteClickHandler({id: week_id, name: week_name})}><BiTrash/></i></td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper}>
        {weekForDelete &&
          <Confirmation
            title= {`Вы уверены что хотите удалить неделю ${weekForDelete.name}?`}
            acceptConfirmation={acceptConfirm}
            rejectConfirmation={rejectConfirm}
          />
        }
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          handleInputChange= {handleInputChange}
          renderOptions={renderOptions}
          formData={formData}
          studyPeriod={studyPeriod}
        />

        <h1>Недели</h1>
        
        <div className={styles.selects}>
          <Select
          title="Год"
          name={"scYear"}
          options={[{name:"Выбрать"}, ...schoolYears]}
          value = {formData?.scYear}
          withoutArrowMode
          onChange={handleInputChange}

          />
          
          <Select
          title="Учебный период"
          name={"studyPeriod"}
          options={[{name:"Выбрать"}, ...studyPeriod]}
          value = {formData?.studyPeriod}
          withoutArrowMode
          onChange={handleInputChange}

          />
        </div>

        <Table style={{marginTop: '2rem', backgroundColor: 'white'}}>
         <thead>
          <tr>
              <th>Название</th>
              <th>Начало</th>
              <th>Окончание</th>
              <th>Примечание</th>
              <th>Вариант расписания</th>
              <th>Вариант расписания ДО</th>
              <th>Вариант расписания ВД</th>
          </tr>
          </thead>
         <tbody>
  
          { highLow?.map(renderLi)}

          </tbody>
        </Table>

        <Button
          style={{padding: '.5rem 1.5rem'}}
          onClick={openModal()}
        >
          <IoIosAdd size={30}/>
          Добавить неделю
        </Button>
        <footer className={styles.footer}>
            <Button mode='white'>Назад</Button>
            <Button mode='white' onClick={goHome}>Закрыть</Button>
        </footer>
    </div>
  )
}