import * as types from "../actions/types";



const initialState = {
  groupInfo: null,
  groupHistory: null,
  groupTeam: null,
  groupCurators: null,
  groups: null,
  modalLoading: false,
  loading: false
};


export default function groups (state = initialState, action) {
  switch (action.type) {
    case types.SET_GROUP_INFO:
      return {
        ...state,
        groupInfo: action.payload,
      };
    case types.SET_GROUP_HISTORY:
      return {
        ...state,
        groupHistory: action.payload,
      };
    case types.SET_GROUP_TEAM:
      return {
        ...state,
        groupTeam: action.payload,
      };
    case types.SET_GROUP_CURATORS:
      return {
        ...state,
        groupCurators: action.payload,
      };
    case types.SET_GROUPS_DATA:
      return {
        ...state,
        groups: action.payload,
      };
    case types.SET_GROUPS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SET_GROUPS_MODAL_LOADING:
      return {
        ...state,
        modalLoading: action.payload,
      };
    default:
      return state;
  }
}