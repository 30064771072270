import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMainData } from '../../../actions/settings/main'
import { SettingsContext } from '../../../utils/context'
import { Checkbox, Input, Select, Textarea } from '../../../components/ModalInputs'
import styles from '../styles.module.scss'

const FORMATS = ['общеобразовательная', 'специальная', 'АПИ в процессе']
const FORMATS1 = [1, 2, 3]
const FORMATS2 = ["г.Москва", "г.Санкт-Петербург"]

export default function Main() {

  const dispatch = useDispatch()
  const { main } = useSelector(state => state.settings)
  const { form } = useContext(SettingsContext)

  const { values, handleChange, handleBlur } = form

  useEffect(() => {
    !main && dispatch(getMainData())
  }, [])


  return (
    <div className={styles.inputsWrapper}>
      <div>

        <Textarea
          label='Полное название учебного заведения'
          name='GOU_FullName'
          style={{ resize: 'none', width: '750px', height: '100px' }}
          value={values.GOU_FullName}
          onChange={handleChange}
        />
        <Input
          label='Сокращенное название'
          name='GOU_ShortName'
          style={{ width: '350px' }}
          value={values.GOU_ShortName}
          onChange={handleChange}
        />

        <Textarea
          label='Заголовок для отчетов'
          name='GOU_Name'
          style={{ resize: 'none', width: '750px', height: '100px' }}
          value={values.GOU_Name}
          onChange={handleChange}

        />
      </div>
      <div className={styles.mainMain}>
        <Textarea
          label='Название департамента'
          name='Dep_Name'
          style={{ resize: 'none', width: '250px', height: '100px' }}
          value={values.Dep_Name}
          onChange={handleChange}
        />
        <Textarea
          label='Логотип'
          name='Dep_Name'
          style={{ resize: 'none', width: '150px', height: '100px' }}
          // value={values.Dep_Name}
          onChange={handleChange}
        />
        <Input
          label='Код по ОКПО'
          name='code'
          style={{ width: '150px' }}
          // value={values.GOU_ShortName}
          onChange={handleChange}
        />
        <Select
          label='Тип образовательной организации'
          name='year'
          value={values.year}
          onChange={handleChange}
        >
          {FORMATS.map(format => <option key={format}>{format}</option>)}
        </Select>
      </div>

      <div className={styles.mainMain}>
        <Textarea
          label='Логотип'
          name='Dep_Name'
          style={{ resize: 'none', width: '250px', height: '250px' }}
          // value={values.Dep_Name}
          onChange={handleChange}
        />
        <div>
          <div style={{ display: 'flex' }}>
            <Textarea
              label='Адрес'
              name='Dep_Name'
              style={{ resize: 'none', width: '450px', height: '100px' }}
              value={values.Dep_Name}
              onChange={handleChange}
            />
            <Select
              label='Субъект РФ'
              name='year'
              value={values.year}
              onChange={handleChange}
            >
              {FORMATS2.map(format => <option key={format}>{format}</option>)}
            </Select>
          </div>
          <div style={{ display: 'flex' }}>
            <Input
              label='Нас. пункт'
              name='code'
              style={{ width: '450px' }}
              // value={values.GOU_ShortName}
              onChange={handleChange}
            />
            <Select
              label='Часовой пояс'
              name='year'
              value={values.year}
              onChange={handleChange}
            >
              {FORMATS1.map(format => <option key={format}>{format}</option>)}
            </Select>
          </div>
          <div style={{ display: 'flex' }}>

            <Input
              label='Телефон'
              name='code'
              style={{ width: '150px' }}
              // value={values.GOU_ShortName}
              onChange={handleChange}
            />
            <Input
              label='Факс'
              name='code'
              style={{ width: '150px' }}
              // value={values.GOU_ShortName}
              onChange={handleChange}
            />
            <Input
              label='E-mail'
              name='code'
              style={{ width: '150px' }}
              // value={values.GOU_ShortName}
              onChange={handleChange}
            />
          </div>

        </div>

      </div>
      <div>
        <div>
          <p>Закрытие журнала</p>
          <div style={{borderBottom: '2px solid black', width: "70vw"}}></div>
          <br></br>
          <div className={styles.mainBlock}>
            <Input
              label='Дата закрытия'
              name='date_from'
              value={values.date_from}
              onChange={handleChange}
              type='date'
            />
            <p>Количество дней до блокировки журнала: </p>
          </div>
          <div className={styles.mainBlock}>
            <Select
              label='Текущие оценки'
              name='year'
              value={values.year}
              onChange={handleChange}
            >
              {FORMATS1.map(format => <option key={format}>{format}</option>)}
            </Select>
            <Select
              label='Итоговые оценки'
              name='year'
              value={values.year}
              onChange={handleChange}
            >
              {FORMATS1.map(format => <option key={format}>{format}</option>)}
            </Select>
            <Checkbox label='В том числе ДО и ВД' />
          </div>
          <div style={{borderBottom: '2px solid black', width: "70vw"}}></div>
          <br></br>
          <div className={styles.mainBlock}>
            <Input
              label='Дата закрытия'
              name='date_from'
              value={values.date_from}
              onChange={handleChange}
              type='date'
            />
            <p>Количество дней до блокировки журнала: </p>
          </div>
          <div className={styles.mainBlock}>
            <Select
              label='Текущие оценки'
              name='year'
              value={values.year}
              onChange={handleChange}
            >
              {FORMATS1.map(format => <option key={format}>{format}</option>)}
            </Select>
            <Select
              label='Итоговые оценки'
              name='year'
              value={values.year}
              onChange={handleChange}
            >
              {FORMATS1.map(format => <option key={format}>{format}</option>)}
            </Select>
            <Checkbox label='В том числе ДО и ВД' />
          </div>

        </div>
      </div>
    </div>
  )
}
