import React from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { useNavigate, useParams } from 'react-router-dom'
import {Access, Employees, Params} from './Tabs'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from 'react-redux'



export default function RoleSettings () {
  
  const { roleId } = useParams()
  const navigate = useNavigate()
  const { groupUsers: group } = useSelector(state => state.roles.permission_groups)

  const goHome = () => navigate('/') 

  const groupName = group?.role_group?.name

  return (
    <div className={styles.wrapper}>
        <h1>{groupName}</h1>
        <Tabs>
            <TabList className={styles.tabsBlock}>
                <Tab className={styles.tab} selectedClassName={styles.activeTab}>Сотрудники</Tab>
                <Tab className={styles.tab} selectedClassName={styles.activeTab}>Доступы</Tab>
                {/* <Tab className={styles.tab} selectedClassName={styles.activeTab}>Параметры</Tab> */}
            </TabList>
            <TabPanel>
                <Employees roleId={roleId}/>
            </TabPanel>
            <TabPanel>
                <Access roleId={roleId}/>
            </TabPanel>
            {/* <TabPanel>
                <Params/>
            </TabPanel> */}
        </Tabs>
        <footer className={styles.footer}>
            <Button mode='white'>Назад</Button>
            <Button mode='white' onClick={goHome}>Закрыть</Button>
        </footer>
    </div>
  )
}
