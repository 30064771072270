import React, { useContext } from 'react'
import Table from '../../../components/Table'
import styles from '../styles.module.scss'
import { useSelector } from 'react-redux'
import { DisciplineStructureContext } from '../../../utils/context'
import Loader from '../../../components/Loader'



export default function TableComponent () {
  
  const {
    tableData,
    selectedRow,
    setSelectedRow,
    editParams,
    setEditParams,
    buttonsBlockRef,
    titleEdit
  } = useContext(DisciplineStructureContext)
  const { loading } = useSelector(state => state.discipline_structure)

  const selectRow = (row, idsList, depth) => () => {
    setSelectedRow(row)
    setEditParams({idsList, depth})
  }
  
  const onBlur = (e) => {
    titleEdit(e)
  }
  
  const renderRows = (ids) => (row, index) => {
    const {title, nr, hrs, oid, expected_results, exercises, children, level} = row
    const paddingLeft = `${level * 100}px`
    const editMode = selectedRow?.oid === row.oid
    const idsList = ids ? [...ids, index] : [ index ]
    const depth = idsList.length - 1;
    return (
      <>
        <tr onClick={selectRow(row, idsList, depth)}>
          <td
            style={{ paddingLeft }}
            className={styles.firstColumn}
            contentEditable
            // onInput={titleEdit}
            onBlur={onBlur}
          >
            {title}
          </td>
          <td>{nr}</td>
          <td>{hrs}</td>
          <td>{expected_results}</td>
          <td>{exercises}</td>
        </tr>
        {children?.map(renderRows(idsList))}
      </>
    )
  }


  return (
    <div className={styles.tableWrapper}>
      {loading
        ? <Loader />
        : !!tableData?.length
          ?  <Table className={styles.table}>
                <thead>
                  <tr>
                    <th></th>
                    <th>№ урока</th>
                    <th>Кол-во часов</th>
                    <th>Планируемые результаты</th>
                    <th>Упражнения</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(renderRows())}
                </tbody>
              </Table>
          : <h3>Данные отсутствуют</h3>
      }
    </div>
  )
}
