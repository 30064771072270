import React from 'react'
import Button from '../../../components/Button'
import styles from '../styles.module.scss'


export default function Footer({ goHome }) {
  return (
    <footer className={styles.footer}>
        <Button mode='white'>Назад</Button>
        <Button mode='white' onClick={goHome}>Закрыть</Button>
    </footer>
  )
}
